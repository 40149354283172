<template>
	<div :class="wrapper">
		<div class="form-check form-switch d-flex align-items-center" :class="this.class">
			<input class="crm-switch form-check-input border-brd-primary form-check-input bg-light-gray text-dark-gray me-10"
				   :class="[this.switch_class, {
						'is-valid': this.valid,
						'is-invalid': this.invalid,
				   }]"
				   :type="this.type"
				   role="switch"
				   ref="switcher"
				   :id="id"
				   :name="name"
				   :value="value"
				   :disabled="this.disabled"
				   :readonly="this.readonly"
				   :required="this.required"
				   :checked="(value > 0) ? true : false"
				   @change="this.$emit('setValue', (this.$refs.switcher.checked) ? 1 : 0)" />
			<label :for="id" class="form-check-label my-auto" :class="this.labelClass">
				<span class="d-inline lh-1">
					{{ this.label }}<sup v-if="this.required" class="text-danger">*</sup>
				</span>
				<span v-if="this.sub_label" class="d-flex flex-fill fs-5" :class="this.subLabelClass">
					{{ this.sub_label }}
				</span>
			</label>
		</div>
		<div v-if="valid && typeof valid !== 'boolean'" class="d-flex flex-fill valid-feedback" v-text="valid" />
		<div v-else-if="invalid && typeof invalid !== 'boolean'" class="d-flex flex-fill invalid-feedback" v-text="invalid" />
		<div v-if="help" class="form-text" v-html="help" />
	</div>
</template>

<script>
export default {
	props: {
		inputId: {type: String},
		inputLabel: {type: String},
		inputLabelClass: {type: String},
		inputSubLabel: {type: String},
		inputSubLabelClass: {type: String},
		inputName: {type: String},
		inputType: {type: String},
		inputValue: {type: [String, Number, Boolean]},
		inputClass: {type: String},
		inputSwitchClass: {type: String},
		inputWrapClass: {type: String},
		inputHelpText: {type: String},
		inputPrepend: {type: String},
		inputAppend: {type: String},
		inputDisabled: {type: [String, Boolean], default: false},
		inputRequired: {type: [String, Boolean], default: false},
		inputReadonly: {type: [String, Boolean], default: false},
		inputValid: {type: [String, Boolean], default: false},
		inputInValid: {type: [String, Boolean], default: false},
	},
	data() {
		return {
			id: (typeof this.inputId !== "undefined") ? this.inputId : '',
			//label: (typeof this.inputLabel !== "undefined") ? this.inputLabel : '',
			//labelClass: (typeof this.inputLabelClass !== "undefined") ? this.inputLabelClass : 'text-secondary',
			//sub_label: (typeof this.inputSubLabel !== "undefined") ? this.inputSubLabel : null,
			//subLabelClass: (typeof this.inputSubLabelClass !== "undefined") ? this.inputSubLabelClass : 'text-secondary',
			name: (typeof this.inputName !== "undefined") ? this.inputName : '',
			type: (typeof this.inputType !== "undefined") ? this.inputType : 'checkbox',
			//value: (typeof this.inputValue !== "undefined") ? this.inputValue : 0,
			class: (typeof this.inputClass !== "undefined") ? this.inputClass : '',
			switch_class: (typeof this.inputSwitchClass !== "undefined") ? this.inputSwitchClass : '',
			wrapper: (typeof this.inputWrapClass !== "undefined") ? this.inputWrapClass : '',
			//help: (typeof this.inputHelpText !== "undefined") ? this.inputHelpText : '',
			//disabled: (typeof this.inputDisabled !== "undefined" && (this.inputDisabled || this.inputDisabled === 'true')),
			//readonly: (typeof this.inputReadonly !== "undefined" && (this.inputDisabled || this.inputReadonly === 'true')),
			valid: (typeof this.inputValid !== "undefined") ? this.inputValid : false,
			invalid: (typeof this.inputInValid !== "undefined") ? this.inputInValid : false,
		};
	},
	watch: {
		'value': function(val, oldVal) {
			this.validateInput(val);
		}
	},
	methods: {
		validateInput() {
			let switcher = this.$refs.switcher;
			if (this.invalid)
				switcher.setCustomValidity(this.invalid);
			else
				switcher.setCustomValidity("");
		}
	},
	mounted() {
		this.validateInput();
	},
	computed: {
		value() {
			return (typeof this.$props.inputValue !== "undefined") ? this.$props.inputValue : 0
		},
		label: {
			get() {
				return (typeof this.$props.inputLabel !== "undefined") ? this.$props.inputLabel : '';
			},
			set(value) {
				value = value.trim();
			}
		},
		disabled() {
			return this.$props.inputDisabled;
		},
		required() {
			return this.$props.inputRequired;
		},
		readonly() {
			return this.$props.inputReadonly;
		},
		help() {
			return (typeof this.$props.inputHelpText !== "undefined") ? this.$props.inputHelpText : '';
		},
		prepend() {
			return (typeof this.$props.inputPrepend !== "undefined") ? this.$props.inputPrepend : false;
		},
		append() {
			return (typeof this.$props.inputAppend !== "undefined") ? this.$props.inputAppend : false;
		},
		labelClass: {
			get() {
				return (typeof this.$props.inputLabelClass !== "undefined") ? this.$props.inputLabelClass : 'text-secondary';
			},
			set(value) {
				value = value.trim();
			}
		},
		sub_label: {
			get() {
				return (typeof this.$props.inputSubLabel !== "undefined") ? this.$props.inputSubLabel : null;
			},
			set(value) {
				value = value.trim();
			}
		},
		subLabelClass: {
			get() {
				return (typeof this.$props.inputSubLabelClass !== "undefined") ? this.$props.inputSubLabelClass : 'text-secondary'
			},
			set(value) {
				value = value.trim();
			}
		}
	}
}
</script>