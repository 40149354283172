<template>
	<div :class="this.wrapper">
		<label v-if="this.label" :for="this.id" class="form-label" :class="this.label !== '' ? this.labelClass : 'visually-hidden'">
			{{ this.label }}<sup v-if="this.required" class="text-danger">*</sup>
		</label>
		<select :id="this.id"
				class="form-select text-truncate"
				:class="[this.class, {
						'is-valid': this.valid,
						'is-invalid': this.invalid,
				   }]"
				style="padding-right: 40px !important;"
				ref="select"
				:aria-label="label"
				:disabled="disabled || readonly"
				:required="required"
				@change="this.$emit('setValue', this.$refs.select.value)">
			<option v-if="use_placeholder"
					:selected="(value == -1 || value == 0 || !value || this.isEmpty(value)) ? true : false"
					:aria-selected="(value == -1 || value == 0 || !value || this.isEmpty(value)) ? 'true' : null"
					:value="0"
					v-text="(placeholder) ? placeholder : 'Не выбрано'" />
			<option v-for="option in options"
					:key="option.value"
					:value="option.value"
					:selected="(!this.isEmpty(value) && value == option.value) ? true : false"
					:aria-selected="(!this.isEmpty(value) && value == option.value) ? 'true' : null"
					v-text="option.name"/>
		</select>
		<div v-if="this.valid && typeof this.valid != 'boolean'" class="valid-feedback" v-html="this.valid" />
		<div v-else-if="this.invalid && typeof this.invalid != 'boolean'" class="invalid-feedback" v-html="this.invalid" />
		<div v-if="this.help" class="form-text" :class="this.helpClass" v-html="this.help" />
	</div>
</template>


<script>
import CommonService from "@/services/CommonService";

export default {
	props: {
		inputId: {type: String},
		inputLabel: {type: String},
		inputLabelClass: {type: String},
		inputName: {type: String},
		inputValue: {type: [Number, String]},
		inputOptions: {type: Object},
		inputClass: {type: String},
		inputWrapClass: {type: String},
		inputHelpText: {type: String},
		inputHelpClass: {type: String},
		inputPlaceholder: {type: String},
		inputUsePlaceholder: {type: [String, Boolean]},
		inputDisabled: {type: [String, Boolean], default: false},
		inputRequired: {type: [String, Boolean], default: false},
		inputReadonly: {type: [String, Boolean], default: false},
		inputValid: {type: [String, Boolean], default: false},
		inputInValid: {type: [String, Boolean], default: false},
	},
	data() {
		return {
			id: (typeof this.inputId !== "undefined") ? this.inputId : '',
			label: (typeof this.inputLabel !== "undefined") ? this.inputLabel : '',
			labelClass: (typeof this.inputLabelClass !== "undefined") ? this.inputLabelClass : 'text-dark-gray',
			name: (typeof this.inputName !== "undefined") ? this.inputName : '',
			//value: (typeof this.inputValue !== "undefined") ? this.inputValue : '',
			//options: (typeof this.inputOptions !== "undefined") ? this.inputOptions : [],
			class: (typeof this.inputClass !== "undefined") ? this.inputClass : '',
			wrapper: (typeof this.inputWrapClass !== "undefined") ? this.inputWrapClass : '',
			help: (typeof this.inputHelpText !== "undefined") ? this.inputHelpText : '',
			helpClass: (typeof this.inputHelpClass !== "undefined") ? this.inputHelpClass : '',
			placeholder: (typeof this.inputPlaceholder !== "undefined") ? this.inputPlaceholder : '',
			use_placeholder: (typeof this.inputUsePlaceholder !== "undefined") ? this.inputUsePlaceholder : true,
			//disabled: (typeof this.inputDisabled !== "undefined" && this.inputDisabled === 'true'),
			//required: (typeof this.inputRequired !== "undefined" && this.inputRequired === 'true'),
			//readonly: (typeof this.inputReadonly !== "undefined" && this.inputReadonly === 'true'),
			valid: (typeof this.inputValid !== "undefined") ? this.inputValid : false,
			invalid: (typeof this.inputInValid !== "undefined") ? this.inputInValid : false,
		};
	},
	watch: {
		'value': function(val, oldVal) {
			this.validateInput(val);
		}
	},
	methods: {
		isEmpty(data) {
			return CommonService.isEmpty(data);
		},
		inArray(needle, haystack) {
			return CommonService.inArray(needle, haystack);
		},
		validateInput() {
			let select = this.$refs.select;
			if (typeof select !== "undefined") {
				if (this.invalid)
					select.setCustomValidity(this.invalid);
				else
					select.setCustomValidity("");
			}
		}
	},
	mounted() {
		this.validateInput();
	},
	computed: {
		value: {
			get() {
				return (typeof this.$props.inputValue !== "undefined") ? this.$props.inputValue : '';
			},
			set(value) {
				value = value.trim();
			}
		},
		options() {

			if (!CommonService.isEmpty(this.$props.inputOptions))
				return this.$props.inputOptions;

			return [];
		},
		disabled() {
			return this.$props.inputDisabled
		},
		required() {
			return this.$props.inputRequired
		},
		readonly() {
			return this.$props.inputReadonly
		},
	},
}
</script>